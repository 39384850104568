import ReactGA from 'react-ga4';

const getGA4Id = () => {
  if (!window.CLIENT_INFO) return false;
  return window.CLIENT_INFO.ga_id;
};

const initializeGoogleAnalytics = () => {
  if (!getGA4Id()) return;
  ReactGA.initialize([
    {
      trackingId: getGA4Id(),
    },
  ]);
};

const trackEvent = (event, data) => {
  if (!ReactGA.isInitialized) initializeGoogleAnalytics();
  ReactGA.event(event, gaParams(data));
};

const kajabiPaymentsParams = (offer) => {
  if (!offer.paymentMethods?.includes('kajabi_payments')) return {};

  return {
    ...offer.additionalPaymentMethodsStatuses,
    applePayDisplayed: !!window.ApplePaySession,
  };
};

const gaParams = (data) => {
  if (!data) return null;
  const { price, offer, site, member } = data;
  return {
    siteId: site.id,
    siteName: site.title,
    offerId: offer.id,
    offerToken: offer.token,
    offerType: price.priceType,
    quantityRemainingDisplayed: quantityRemainingDisplayed(offer),
    quantityRemaining: offer.quantityRemaining,
    memberId: member?.id,
    checkoutVersion: 'popup',
    ...kajabiPaymentsParams(offer),
  };
};

const quantityRemainingDisplayed = (offer) =>
  offer.quantityRemaining !== undefined &&
  offer.quantityLimitDisplayThreshold !== undefined &&
  offer.quantityLimitDisplayThreshold >= offer.quantityRemaining;

export { trackEvent };
