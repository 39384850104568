import { Icon } from '@kajabi/sage-react';
import React from 'react';
import PropTypes from 'prop-types';

export const OfferListItemImage = ({ imageSrc, icon }) => {
  if (imageSrc) {
    return (
      <div className="price-summary__icon">
        <img src={imageSrc} className="price-summary__image" alt="offer_image" loading="lazy" />
      </div>
    );
  }
  return (
    <Icon
      color={Icon.COLORS.GREY_600}
      icon={icon}
      size={Icon.SIZES.LG}
      className="price-summary__icon"
      label={icon}
    />
  );
};

OfferListItemImage.propTypes = {
  imageSrc: PropTypes.string,
  icon: PropTypes.string,
};

OfferListItemImage.defaultProps = {
  imageSrc: null,
  icon: Icon.ICONS.PRODUCT,
};
