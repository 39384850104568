import React from 'react';
import uuid from 'react-uuid';
import { Modal, Button } from '@kajabi/sage-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AbandonmentModal = ({
  showModal,
  handleAbandonmentCancel,
  handleAbandonmentConfirm,
  content,
  disableBackground,
  escKeyCancelsAbandonment,
}) => {
  const { t } = useTranslation();

  const modalContent = {
    title: content?.title ? content.title : t('abandonment_modal.title'),
    body: content?.body ? content.body : t('abandonment_modal.body'),
    leaveButton: content?.leaveButton ? content.leaveButton : t('abandonment_modal.leave_button'),
    continueButton: content?.continueButton
      ? content.continueButton
      : t('abandonment_modal.continue_button'),
    id: `pop-abandonment-modal-${uuid()}`,
  };

  const handleKeyUp = (e) => {
    if (!escKeyCancelsAbandonment || e.key !== 'Escape') return;
    handleAbandonmentCancel();
  };

  return (
    <Modal
      active={showModal}
      size={Modal.SIZES.SM}
      id={modalContent.id}
      className="abandonment-modal sage-scoped"
      disableBackgroundBlur={disableBackground}
      disableBackgroundDismiss={disableBackground}
      data-testid={modalContent.id}
      onKeyUp={
        escKeyCancelsAbandonment
          ? (e) => {
              handleKeyUp(e);
            }
          : null
      }
      tabIndex="-1"
    >
      <Modal.Header title={modalContent.title} />
      <Modal.Body>{modalContent.body}</Modal.Body>
      <Modal.Footer>
        <Button color={Button.COLORS.SECONDARY} onClick={handleAbandonmentConfirm}>
          {modalContent.leaveButton}
        </Button>
        <Button color={Button.COLORS.PRIMARY} onClick={handleAbandonmentCancel}>
          {modalContent.continueButton}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

AbandonmentModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleAbandonmentCancel: PropTypes.func.isRequired,
  handleAbandonmentConfirm: PropTypes.func.isRequired,
  content: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
    leaveButton: PropTypes.string,
    continueButton: PropTypes.string,
  }),
  disableBackground: PropTypes.bool,
  escKeyCancelsAbandonment: PropTypes.bool,
};

AbandonmentModal.defaultProps = {
  content: null,
  disableBackground: false,
  escKeyCancelsAbandonment: false,
};

export default AbandonmentModal;
