import React from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';

const SubscriptionFrequencyText = ({ intervalCount, interval }) => {
  const { t } = useTranslation();

  return (
    <>
      {intervalCount === 1
        ? t(`form.${interval}`)
        : `${capitalize(t('form.every'))} ${intervalCount} ${t(`form.${interval}`, {
            count: intervalCount,
          })}`}
    </>
  );
};

SubscriptionFrequencyText.propTypes = {
  intervalCount: PropTypes.number,
  interval: PropTypes.string.isRequired,
};

SubscriptionFrequencyText.defaultProps = {
  intervalCount: 1,
};

export default SubscriptionFrequencyText;
