import React from 'react';
import ReactDom from 'react-dom';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { initializeSentry } from 'common/components/Sentry/Sentry';

import { initialValues, validationSchema } from 'apps/commerce/common/checkout/constants';
import ToastProvider from 'apps/commerce/common/checkout/context/toast/ToastProvider';
import PopupCheckout from '../../../apps/commerce/popup_checkout/PopupCheckout';

export function FormContainer({ children }) {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
}

document.addEventListener('DOMContentLoaded', () => {
  // find all pop up checkout anchors in the dom
  const popupLinks = document.querySelectorAll('a[href^="#popup_checkout"]');

  if (!popupLinks.length) return;

  initializeSentry({ tags: { team: 'Commerce', component: 'popup-checkout' } });

  document.body.classList.add('sage-excluded');

  popupLinks.forEach((link, i) => {
    // wrap the link in a span so we have a target to render the react component into
    link.outerHTML = `<span key='popup-checkout-${i}' id='popup-checkout-${i}'>${link.outerHTML}</span>`;

    // find the wrapper div
    const wrapper = document.querySelector(`#popup-checkout-${i}`);

    // extract the offer token from the hash
    const offerToken = link.href.split('#').pop().replace('popup_checkout_', '');

    // determine if we are in the theme editor
    const isEditing = !!window.Kajabi?.theme?.editor;

    // render the react component into the wrapper span
    ReactDom.render(
      <Sentry.ErrorBoundary>
        <FormContainer>
          <ToastProvider>
            <PopupCheckout el={link.outerHTML} offerToken={offerToken} enableModal={!isEditing} />
          </ToastProvider>
        </FormContainer>
      </Sentry.ErrorBoundary>,
      wrapper,
    );
  });
});

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
};
