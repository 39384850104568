import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SageClassnames, Frame, Panel, Button, Tooltip } from '@kajabi/sage-react';
import { useFormContext } from 'react-hook-form';
import SubscriptionFrequencyText from './SubscriptionFrequencyText';

const OrderBumpDescription = ({ description, truncate, setTruncate }) => {
  const { t } = useTranslation();
  if (!description) return null;
  const shouldTruncate = description.length > 200;
  const toggleTruncate = () => setTruncate(!truncate);
  const truncatedText = truncate ? t('form.read_more') : t('form.read_less');
  const truncatedClass = truncate ? 'truncate' : '';
  return (
    <div>
      <div
        className={`order-bump__description sage-spacer-bottom-xs ${truncatedClass}`}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: description }}
      />
      {shouldTruncate && (
        <div className={`order-bump__read-more ${truncate ? 'truncate' : ''}`}>
          <Button small subtle onClick={toggleTruncate} data-testid="order-bump-description-toggle">
            <span
              className={`
              ${SageClassnames.TYPE.BODY_SEMI}
              ${SageClassnames.TYPE_COLORS.PRIMARY_300}
            `}
            >
              {truncatedText}
            </span>
          </Button>
        </div>
      )}
    </div>
  );
};

OrderBumpDescription.propTypes = {
  description: PropTypes.string.isRequired,
  truncate: PropTypes.bool.isRequired,
  setTruncate: PropTypes.func.isRequired,
};

const OrderBump = ({ src }) => {
  const { t } = useTranslation();
  const orderBump = src;
  const { setValue, watch } = useFormContext();
  const { price, displayPrice, isRecurring, description, title } = orderBump;
  const { paymentType, interval, intervalCount, numberOfPayments } =
    price.subscriptionPlanAttributes || {};
  const isOrderBumpActive = watch('orderBumpActive');
  const giftDetails = watch('giftDetails') || {};
  const isSubscription = paymentType === 'subscription';
  const disableOrderBump = Object.keys(giftDetails).length > 0;

  useEffect(() => {
    if (disableOrderBump) {
      setValue('orderBumpActive', false);
    }
  }, [disableOrderBump]);

  const RecurringText = () => {
    if (isSubscription) {
      return <SubscriptionFrequencyText intervalCount={intervalCount} interval={interval} />;
    }
    return <span>{t('form.number_of_monthly_payments', { numberOfPayments })}</span>;
  };
  const [truncate, setTruncate] = React.useState(true);

  const wrapTooltip = (disableText, children) =>
    disableText ? (
      <Tooltip data-testid="order-bump-tooltip" content={disableText} position="top">
        <div style={{ display: 'inline-block' }}>{children}</div>
      </Tooltip>
    ) : (
      <>{children}</>
    );

  return (
    <Panel className="order-bump__panel">
      <div className="order-bump__container">
        <div
          className={`
          order-bump__title
          ${truncate ? 'truncate' : ''}
          ${SageClassnames.TYPE.BODY_SEMI}
        `}
        >
          {title}
        </div>
        <Frame
          direction={Frame.DIRECTIONS.HORIZONTAL}
          gap={Frame.GAPS.XS}
          align={Frame.ALIGNMENTS.CENTER_LEFT}
        >
          <Frame
            direction={Frame.DIRECTIONS.HORIZONTAL}
            align={Frame.ALIGNMENTS.CENTER_LEFT}
            gap={Frame.GAPS.XS}
            className="price-detail"
          >
            <div>
              <div className={`${SageClassnames.TYPE.HEADING_6}`}>{displayPrice}</div>
              {isRecurring && (
                <div
                  className={`
                    ${SageClassnames.TYPE.BODY_SEMI}
                    ${SageClassnames.TYPE_COLORS.GREY_700}
                    ${SageClassnames.TYPE.BODY_SMALL}
                  `}
                  data-testid="order-bump-subscription"
                >
                  <RecurringText />
                </div>
              )}
            </div>
          </Frame>
          {wrapTooltip(
            disableOrderBump ? t('tooltips.gift_addons_disabled') : null,
            <Button
              small
              disabled={disableOrderBump}
              onClick={() => setValue('orderBumpActive', !isOrderBumpActive)}
              color={isOrderBumpActive ? Button.COLORS.SECONDARY : Button.COLORS.PRIMARY}
              data-testid="order-bump-button"
            >
              {isOrderBumpActive ? t('form.remove') : t('form.add')}
            </Button>,
          )}
        </Frame>
      </div>
      <OrderBumpDescription
        description={description}
        truncate={truncate}
        setTruncate={setTruncate}
      />
    </Panel>
  );
};

OrderBump.propTypes = {
  src: PropTypes.shape({
    title: PropTypes.string,
    isRecurring: PropTypes.bool,
    displayPrice: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.shape({
      priceType: PropTypes.string,
      priceStrategy: PropTypes.string,
      subscriptionPlanAttributes: PropTypes.shape({
        paymentType: PropTypes.string,
        interval: PropTypes.string,
        intervalCount: PropTypes.number,
        numberOfPayments: PropTypes.number,
        trialPeriodDays: PropTypes.number,
        setupFee: PropTypes.number,
        setupFeeTextOverride: PropTypes.string,
      }),
    }),
  }).isRequired,
};

export default OrderBump;
