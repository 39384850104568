import { SageClassnames, Badge } from '@kajabi/sage-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import SubscriptionFrequencyText from './SubscriptionFrequencyText';
import { localizedPriceFromMinorUnit } from '../util';

export const PriceDisplay = ({ offer, displayPrice, layoutType }) => {
  const { t } = useTranslation();
  const { getValues } = useFormContext();
  const { price, quantity, quantityLimitDisplayThreshold, quantityRemaining } = getValues();
  const isRecurring = price.priceType === 'recurring';
  const isTrialSubscription = price.subscriptionPlanAttributes?.trialPeriodDays > 0;
  const { trialPeriodDays } = price.subscriptionPlanAttributes || {};
  const { paymentType, interval, intervalCount, numberOfPayments } =
    price.subscriptionPlanAttributes || {};
  const formattedOfferPrice = localizedPriceFromMinorUnit(
    price.currency,
    price.amount,
    price.exponent,
  );
  const showOffersRemaining = quantityRemaining <= quantityLimitDisplayThreshold;
  const { title: offerTitle, imageUrl: offerImage } = offer;

  const priceDisplay = (
    <h4 id="offer-price" className={`price-display__offer-price ${SageClassnames.TYPE.HEADING_4}`}>
      <span>{displayPrice}</span>
    </h4>
  );

  const quantityDisplay = (
    <>
      <h4
        id="offer-price"
        className={`price-display__offer-price ${SageClassnames.TYPE.HEADING_4}`}
      >
        <span>
          {localizedPriceFromMinorUnit(price.currency, price.amount * quantity, price.exponent)}
        </span>
      </h4>
      <div className={SageClassnames.TYPE_COLORS.GREY_600}>
        <span>{formattedOfferPrice}</span>
        <span>/{t('form.each')}</span>
      </div>
    </>
  );

  let priceTitle = quantity > 1 ? quantityDisplay : priceDisplay;
  if (offer.priceTextOverride) {
    priceTitle = priceDisplay;
  }

  return (
    <div
      className={`price-display ${offerImage ? 'price-display--image' : ''}`}
      data-display-layout={layoutType}
    >
      <h3
        className={`price-display__title ${SageClassnames.TYPE.HEADING_6} ${SageClassnames.TYPE.BODY_BOLD}`}
      >
        {offerTitle}
      </h3>
      <div className="price-display__description">
        {offerImage && (
          <img
            src={offerImage}
            className="price-display__image"
            width="174"
            height="98"
            alt=""
            loading="lazy"
          />
        )}
        <div className="price-display__offer">
          {priceTitle}
          {isRecurring && paymentType === 'multipay' && (
            <small className="price-display__offer-interval t-sage-body-small">
              {numberOfPayments} {t(`form.${interval}`).toLowerCase()} {t('form.payments')}
            </small>
          )}
          {isRecurring && paymentType === 'subscription' && (
            <small className="price-display__offer-interval t-sage-body-small">
              <SubscriptionFrequencyText intervalCount={intervalCount} interval={interval} />
            </small>
          )}
          {isTrialSubscription && (
            <div className="trial-details">
              <Badge isInteractive={false} value={`${trialPeriodDays} ${t('form.trial_days')}`} />
            </div>
          )}
          {showOffersRemaining && (
            <span
              className={`${SageClassnames.TYPE.BODY_SMALL} ${SageClassnames.TYPE_COLORS.RED_500}`}
            >
              {t('form.items_remaining', { quantity: quantityRemaining })}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};

PriceDisplay.defaultProps = {
  layoutType: 'lg',
};

PriceDisplay.propTypes = {
  displayPrice: PropTypes.string.isRequired,
  offer: PropTypes.shape({
    title: PropTypes.string,
    imageUrl: PropTypes.string,
  }).isRequired,
  layoutType: PropTypes.string,
};
