import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Input } from '@kajabi/sage-react';

function TaxIdInput({ disabled }) {
  const [taxId, setTaxId] = React.useState('');
  const { getValues, setValue } = useFormContext();
  const { taxIdValidationStatus } = getValues();
  const { t } = useTranslation();

  const handleChange = (event) => {
    setTaxId(event.target.value);
    setValue('taxId', event.target.value, { shouldValidate: true });
  };
  const hasError = taxIdValidationStatus && taxIdValidationStatus !== 'valid';
  return (
    <Input
      label={t('form.tax_id')}
      placeholder={t('form.tax_id')}
      id="tax_id"
      value={taxId}
      onChange={handleChange}
      disabled={disabled}
      hasError={hasError}
      message={hasError ? t('form.invalid_tax_id') : null}
    />
  );
}

TaxIdInput.propTypes = {
  disabled: PropTypes.bool,
};

TaxIdInput.defaultProps = {
  disabled: false,
};

export default TaxIdInput;
