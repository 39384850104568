import React from 'react';

export const CloseIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <title>Close</title>
    <path
      d="M11.1384 1.80458C11.3987 1.54423 11.3987 1.12212 11.1384 0.861766C10.878 0.601416 10.4559 0.601416 10.1956 0.861766L6.00033 5.05703L1.80506 0.861766C1.54471 0.601416 1.1226 0.601416 0.862254 0.861766C0.601905 1.12212 0.601905 1.54423 0.862254 1.80458L5.05752 5.99984L0.862254 10.1951C0.601905 10.4555 0.601905 10.8776 0.862254 11.1379C1.1226 11.3983 1.54471 11.3983 1.80506 11.1379L6.00033 6.94265L10.1956 11.1379C10.4559 11.3983 10.878 11.3983 11.1384 11.1379C11.3987 10.8776 11.3987 10.4555 11.1384 10.1951L6.94313 5.99984L11.1384 1.80458Z"
      fill="#202327"
    />
  </svg>
);
