import { localizedPriceFromMinorUnit } from '../util';

const useOrderBump = (orderBump, taxData) => {
  if (!orderBump) return {};

  const { price, description, title, imageUrl } = orderBump;

  let priceAmount = price.amount;
  if (price.subscriptionPlanAttributes) {
    priceAmount = price.subscriptionPlanAttributes.paymentAmount;
  }
  priceAmount += taxData && taxData.orderBumpOverallAmount ? taxData.orderBumpOverallAmount : 0;
  const isRecurring = price.priceType === 'recurring';
  const displayPrice = localizedPriceFromMinorUnit(price.currency, priceAmount, price.exponent);
  return {
    price,
    title,
    displayPrice,
    description,
    isRecurring,
    imageUrl,
  };
};

export default useOrderBump;
